import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  CREATE_STUDENT_REG_FAILURE,
  CREATE_STUDENT_REG_REQUEST,
  CREATE_STUDENT_REG_SUCCESS,
  DELETE_STUDENT_REG_FAILURE,
  DELETE_STUDENT_REG_REQUEST,
  DELETE_STUDENT_REG_SUCCESS,
  GET_CAST_FAILURE,
  GET_CAST_REQUEST,
  GET_CAST_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_STUDENT_REG_FAILURE,
  GET_STUDENT_REG_REQUEST,
  GET_STUDENT_REG_SUCCESS,
  STUDENT_FOR_DELETE_FAILURE,
  STUDENT_FOR_DELETE_REQUEST,
  STUDENT_FOR_DELETE_SUCCESS,
  STUDENT_LIST_FOR_DELETE_FAILURE,
  STUDENT_LIST_FOR_DELETE_REQUEST,
  STUDENT_LIST_FOR_DELETE_SUCCESS,
  UPDATE_STUDENT_REG_FAILURE,
  UPDATE_STUDENT_REG_REQUEST,
  UPDATE_STUDENT_REG_SUCCESS,
} from "../../Constants/school_user/StudentRegistration";
const school_user = JSON.parse(localStorage.getItem("school_user"));

const admissionUrl = "/student-admission";
let isPresent = school_user?.school_modules?.find((modu) => {
  return modu?.menus_data?.some((menu) => menu?.page_url === admissionUrl) ?? false;
});


export const getCategory = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CATEGORY_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(`${BASE_URL}get_category/`, {}, config);
      const data = response.data;
      dispatch({ type: GET_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CATEGORY_FAILURE,
        payload: error,
      });
    }
  };
};
export const getCast = (token, cast_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CAST_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_caste/`,
        cast_id,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CAST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CAST_FAILURE,
        payload: error,
      });
    }
  };
};

export const getStudentList = (token, cast_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STUDENT_REG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_student_list/`,
        cast_id,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STUDENT_REG_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: GET_STUDENT_REG_FAILURE,
        payload: error,
      });
    }
  };
};

export const DeleteStudent = (token, cast_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STUDENT_FOR_DELETE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_school_wise_student/`,
        cast_id,
        config
      );
      const data = response.data;
      dispatch({ type: STUDENT_FOR_DELETE_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: STUDENT_FOR_DELETE_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSchoolWiseStudent = (token, cast_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STUDENT_LIST_FOR_DELETE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_school_wise_student/`,
        cast_id,
        config
      );
      const data = response.data;
      dispatch({ type: STUDENT_LIST_FOR_DELETE_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: STUDENT_LIST_FOR_DELETE_FAILURE,
        payload: error,
      });
    }
  };
};

export const createStudentReg = (token, getstudent, navigate, clearField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_STUDENT_REG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${BASE_URL}student_registration/`,
        getstudent,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_STUDENT_REG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          title: "Info",
          text: `Student No ${response.data.payload.student_code}`,
          // text: `Student No ${response.data.payload.}`
          icon: "info",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "SAVE PROCEED",
              text: "Record Saved Successfully. Do you want to Proceed for Admission?",
              icon: "info",
              showDenyButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes",
              denyButtonText: "No",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(
                  isPresent
                    ? `/school${admissionUrl}`
                    : "/school/student-registration",
                  { state: { id: response.data.payload.student_code } }
                );
                clearField();
              } else if (result.isDenied) {
                navigate("/school/student-registration");
                clearField();
              }
            });
          }
        });
      }

      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_STUDENT_REG_FAILURE,
        payload: error,
      });
    }
  };
};
export const updateStudentReg = (token, getstudent, navigate, clearField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STUDENT_REG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${BASE_URL}update_student_registration/`,
        getstudent,
        config
      );
      const data = response.data;
      dispatch({ type: UPDATE_STUDENT_REG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          // title: "Info",
          text: response.data.message,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/school/student-registration");
          }
        });
      }

      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_STUDENT_REG_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteStudentReg = (token, sdata, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_STUDENT_REG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}delete_student_registration/`,
        sdata,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_STUDENT_REG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.message,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/school/student-registration");
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/school/student-registration");
          }
        });
      }
      if (response.data.status === 400) {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/school/student-registration");
          }
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_STUDENT_REG_FAILURE,
        payload: error,
      });
    }
  };
};

const initialState = {
  loading: false,
  students: [],
  error: null,
};

const studentListForDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_LIST_FOR_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case STUDENT_LIST_FOR_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload, // Assuming action.payload contains the list of students
      };
    case STUDENT_LIST_FOR_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload, // Assuming action.payload contains the error message
      };
    default:
      return state;
  }
};

export default studentListForDeleteReducer;




