import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionContext } from "../../../context/sessionContext";
import { Modal } from "reactstrap";
import { getStudentList } from "../../../actions/school_user/StudentRegistration";
import Pagination from "react-js-pagination";
import { convertDate } from "../../CommonComponent/DateFormatUtil";
import {
  CustomModalBody,
  CustomModalHeader,
} from "../../CommonComponent/modelComponent";
const StudentListSessionWize2 = (props) => {
  const { setstudent_uid, stu_session, setStudent, callFrom ,setDisabledAll ,setSelectDiscountReason ,handleClear2 } = props;
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const { studentdata } = useSelector((state) => state.studentRegistration);
  const { academicStudentList } = useSelector(
    (state) => state.getAcademicFilterStudentList
  );




  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  //   const [search, setSearch] = useState("") 
  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
    setCurrentPage(1);
    setIdQuery("")
    setSearchQuery("")
  
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    let filteredResults;
    filteredResults = studentdata?.payload?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };

  let body = {
    fk_academic: session_id,
    school_id: s_id,
    student_name: searchQuery,
    student_code: idQuery,
    page: CurrentPage,
    status_type: "Admitted",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const res = await dispatch(getStudentList(token, body));
          setPage(res.totalRecordCount);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, token, session_id, s_id, searchQuery, idQuery, CurrentPage]);

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    {
      callFrom === "RoomAllotment" && setStudent(data);
    }
    setModal(!modal);
    setDisabledAll(false)
    handleClear2()
  };

  useEffect(() => {
    setSearchResults(studentdata?.payload);
  }, [studentdata]);

  return (
    <>
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          data-toggle="tooltip"
          data-placement="top"
          title="View Student List"
          type="button"
          onClick={() => {
            // setSearch(stuList);
            toggle();
          }}
        >
          <i className="fa fa-search"> </i>
        </button>
      </div>

      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <CustomModalHeader toggle={toggle}>
          <div className="row">
            <div className="col-lg-5 col-md-5 my-1">
              <input
                name="code"
                type="text"
                className="form-control"
                placeholder="Student code"
                value={idQuery}
                onChange={handleIdInputChange}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-5 col-md-5 my-1">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Student Name"
                value={searchQuery}
                onChange={handleInputChangeSearch}
                autoComplete="off"
              />
            </div>
            <div className="col-md-2 col-lg-2 my-1">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </CustomModalHeader>
        <CustomModalBody noResultsMessage={noResultsMessage}>
          <thead>
            <tr className="text-center">
              <th>Registration Date</th> 
              <th>Stud Code.</th>
              <th>Roll no</th>
              <th>Student Name</th>
              <th>Class section</th>
              <th>Gender</th>
              {/* <th>DOB</th> */}
              {/* <th>Category</th> */}
              {/* <th>Caste</th> */}
              {/* <th>Sub Caste</th> */}
              <th>Student Status</th>
              {/* <th>Enrollment No</th> */}
              <th>Academic Session</th>
            </tr>
          </thead>
          <tbody>
            {searchResults?.map((stu, index) => (
              <tr
                key={index}
                className="text-center"
                onClick={() => handleStudentEdit(stu)}
                style={{ cursor: "pointer" }}
              >
                <td>{convertDate(stu.registration_date)}</td>
                <td>{stu.student_code}</td>
                <td>{stu.roll_no}</td>
                <td className="text-left">{stu.std_name}</td>
                <td>{stu.class_section}</td>
                <td>{stu.gender}</td>
                {/* <td>{convertDate(stu.date_of_birth)}</td> */}
                {/* <td>{stu.fk_category_name}</td> */}
                {/* <td>{stu.fk_caste_name}</td> */}
                {/* <td>{stu.sub_caste}</td> */}
                <td>{stu.student_status}</td>
                {/* <td></td> */}
                <td>{stu?.academic_year}</td>
              </tr>
            ))}
          </tbody>
        </CustomModalBody>
        <ul className="pagination justify-content-center">
          {Page && (
            <Pagination
              activePage={CurrentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={Page}
              hideFirstLastPages={false}
              onChange={(e) => setCurrentPage(e)}
              nextPageText="Next"
              prevPageText="Previous"
              lastPageText="Last"
              firstPageText="1st"
              itemClass="page-item"
              linkClass="page-link"
              activeClass="active"
              activeLinkClass="active"
              hideDisabled={false}
            />
          )}
        </ul>
        {/* <ModalFooter></ModalFooter> */}
      </Modal>
    </>
  );
};

export default StudentListSessionWize2;
